import React from 'react';
import styled from 'styled-components';

import Content from '../common/content';
import Flex from '../common/flex';
import Layout from '../layout';
import theme, { Theme } from '../theme';

const Heading = styled(Content.Heading)`
  font-size: 1.5rem;
  text-align: center;
`;

const SubHeading = styled(Content.SubHeading)`
  color: ${theme.color.black};
  font-size: ${theme.size.heading};
  text-align: center;
`;

const Offline: React.FC = () => (
  <Theme>
    <Layout showProfile={false}>
      <Flex.Col className="o-height-100 o-page-background" align="middle" justify="center">
        <Heading>Milliman Optic&trade; is undergoing scheduled maintenance</Heading>
        <SubHeading>The tool will be available again shortly</SubHeading>
      </Flex.Col>
    </Layout>
  </Theme>
);

export default Offline;
